<script lang="ts" setup>
import { useMainMenu } from './useMainMenu'
import { useSubmenuVisibility } from './useSubmenuVisibility'
const { menu } = await useMainMenu()

const { activeId, onClick, closeSubMenus } = useSubmenuVisibility()
const onMouseLeave = () => closeSubMenus()
</script>
<template>
  <div class="navigation" @mouseleave="onMouseLeave">
    <MainMenu :menu="menu" :active-id="activeId" @click="onClick" />
    <SubMenus :menu="menu" :active-id="activeId" />
  </div>
</template>
<style lang="scss" scoped>
.menu {
  display: flex;
  gap: var(--gap);
}
.link {
  display: grid;
  place-content: center;
  height: var(--header-bar-height);

  border-bottom: 3px solid transparent;

  &.is-active {
    border-color: var(--c-primary);
  }
}
</style>
